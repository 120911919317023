import React from 'react';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      decision: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleSubmit = (event) => {
    event.preventDefault();

    // Prepare the data
    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      decision: this.state.decision,
      other: this.state.other,
    };

    // Format the email content
    const emailBody = `First Name: ${data.firstName}%0D%0A
Last Name: ${data.lastName}%0D%0A
Email: ${data.email}%0D%0A
Decision: ${data.decision}%0D%0A
Other: ${data.other.replace(/\r?\n|\r/g, "%0D%0A")}`;

    // Open the default mail application with the form data
    window.location.href = `mailto:your@email.com?subject=Ansökan om att bli ihop&body=${emailBody}`;

    // Clear the form fields
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      decision: "",
      other: "",
    });
  };
  render() {
    return (
        <div className="App">
          <h1>Ansökan</h1>
          <p>Fyll i vårt superroliga och spännande formulär för att ansöka om medlemskap i Mikaelklubben! Håll i dig, för inom 24 timmar får du reda på om du är den utvalda som får skratta och leva livet med Mikael Carlstein! 🎉</p>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label>First name:</label>
              <input
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  pattern="Sofia"
                  title="Only 'Sofia' is allowed"
                  required
              />
            </div>
            <div className="form-group">
              <label>Last name:</label>
              <input
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  pattern="Sjölander"
                  title="Only 'Sjölander' is allowed"
                  required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input type="email" name="email" value={this.state.email} onChange={this.handleChange} required/>
            </div>
            <div className="form-group">
              <label>Decision:</label>
              <select name="decision" value={this.state.decision} onChange={this.handleChange} required>
                <option value="" disabled></option>
                <option value="yes">Yes</option>
                <option value="no" disabled>No</option>
                <option value="maybe" disabled>Maybe</option>
              </select>
            </div>
            <div className="form-group">
              <label>Other:</label>
              <textarea
                  name="other"
                  value={this.state.other}
                  onChange={this.handleChange}
                  rows="4"
                  cols="50"
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
    );
  }
}

export default App;